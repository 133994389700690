var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-5"},[_vm._v("Étude Cinétique - Catalase: Donées & Calculs - Partie B")]),_c('h3',{staticClass:"mb-4"},[_vm._v("Donées")]),_c('p',{staticClass:"mb-n1"},[_c('v-text-field',{attrs:{"prefix":"Tableau 1. ","label":"Tappez une légende pour le tableau ici"},model:{value:(_vm.inputs.table1Caption),callback:function ($$v) {_vm.$set(_vm.inputs, "table1Caption", $$v)},expression:"inputs.table1Caption"}})],1),_c('p',{staticClass:"pl-n8 mb-5"},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('td',{staticStyle:{"font-weight":"bold","text-align":"left","width":"36%"}},[_vm._v("Propriété")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"center","width":"22%"}},[_vm._v("Essai 1")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"center","width":"22%"}},[_vm._v("Essai 2")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"center"}},[_vm._v("Unité")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("V (catalase)")]),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vCatalaseBT1),callback:function ($$v) {_vm.$set(_vm.inputs, "vCatalaseBT1", $$v)},expression:"inputs.vCatalaseBT1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vCatalaseBT2),callback:function ($$v) {_vm.$set(_vm.inputs, "vCatalaseBT2", $$v)},expression:"inputs.vCatalaseBT2"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('v-select',{attrs:{"items":_vm.unitOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.unitCatalaseB),callback:function ($$v) {_vm.$set(_vm.inputs, "unitCatalaseB", $$v)},expression:"inputs.unitCatalaseB"}})],1)]),_c('tr',[_c('td',[_vm._v("V "),_c('chemical-latex',{attrs:{"content":"(H2O2)"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vH2O2BT1),callback:function ($$v) {_vm.$set(_vm.inputs, "vH2O2BT1", $$v)},expression:"inputs.vH2O2BT1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vH2O2BT2),callback:function ($$v) {_vm.$set(_vm.inputs, "vH2O2BT2", $$v)},expression:"inputs.vH2O2BT2"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('v-select',{attrs:{"items":_vm.unitOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.unitH2O2B),callback:function ($$v) {_vm.$set(_vm.inputs, "unitH2O2B", $$v)},expression:"inputs.unitH2O2B"}})],1)]),_c('tr',[_c('td',[_vm._v("V "),_c('chemical-latex',{attrs:{"content":"(total)"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vTotBT1),callback:function ($$v) {_vm.$set(_vm.inputs, "vTotBT1", $$v)},expression:"inputs.vTotBT1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.vTotBT2),callback:function ($$v) {_vm.$set(_vm.inputs, "vTotBT2", $$v)},expression:"inputs.vTotBT2"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('v-select',{attrs:{"items":_vm.unitOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.unitVtotB),callback:function ($$v) {_vm.$set(_vm.inputs, "unitVtotB", $$v)},expression:"inputs.unitVtotB"}})],1)]),_c('tr',[_c('td',[_c('chemical-latex',{attrs:{"content":"\\%~H2O2"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.pctH2O2BT1),callback:function ($$v) {_vm.$set(_vm.inputs, "pctH2O2BT1", $$v)},expression:"inputs.pctH2O2BT1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.pctH2O2BT2),callback:function ($$v) {_vm.$set(_vm.inputs, "pctH2O2BT2", $$v)},expression:"inputs.pctH2O2BT2"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0",staticStyle:{"text-align":"center"}},[_c('stemble-latex',{attrs:{"content":"$\\%$"}})],1)]),_c('tr',[_c('td',[_vm._v("Température")]),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.tempBT1),callback:function ($$v) {_vm.$set(_vm.inputs, "tempBT1", $$v)},expression:"inputs.tempBT1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.tempBT2),callback:function ($$v) {_vm.$set(_vm.inputs, "tempBT2", $$v)},expression:"inputs.tempBT2"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('v-select',{attrs:{"items":_vm.unitOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.unitTempB),callback:function ($$v) {_vm.$set(_vm.inputs, "unitTempB", $$v)},expression:"inputs.unitTempB"}})],1)]),_c('tr',[_c('td',[_vm._v("Vitesse Initiale")]),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.initRateBT1),callback:function ($$v) {_vm.$set(_vm.inputs, "initRateBT1", $$v)},expression:"inputs.initRateBT1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.initRateBT2),callback:function ($$v) {_vm.$set(_vm.inputs, "initRateBT2", $$v)},expression:"inputs.initRateBT2"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('v-select',{attrs:{"items":_vm.unitOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.unitInitRateB),callback:function ($$v) {_vm.$set(_vm.inputs, "unitInitRateB", $$v)},expression:"inputs.unitInitRateB"}})],1)])])])],1),_c('h3',{staticClass:"mb-4"},[_vm._v("Calculs")]),_c('p',{staticClass:"mb-n1"},[_c('v-text-field',{attrs:{"prefix":"Tableau 2. ","label":"Tappez une légende pour le tableau ici"},model:{value:(_vm.inputs.table2Caption),callback:function ($$v) {_vm.$set(_vm.inputs, "table2Caption", $$v)},expression:"inputs.table2Caption"}})],1),_c('p',{staticClass:"pl-n8 mb-5"},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('td',{staticStyle:{"font-weight":"bold","text-align":"left","width":"36%"}},[_vm._v("Propriété")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"center","width":"22%"}},[_vm._v("Essai 1")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"center","width":"22%"}},[_vm._v("Essai 2")]),_c('td',{staticStyle:{"font-weight":"bold","text-align":"center"}},[_vm._v("Unité")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Température")]),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.tempET1),callback:function ($$v) {_vm.$set(_vm.inputs, "tempET1", $$v)},expression:"inputs.tempET1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.tempET2),callback:function ($$v) {_vm.$set(_vm.inputs, "tempET2", $$v)},expression:"inputs.tempET2"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('v-select',{attrs:{"items":_vm.unitOptions2,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.unitTempE),callback:function ($$v) {_vm.$set(_vm.inputs, "unitTempE", $$v)},expression:"inputs.unitTempE"}})],1)]),_c('tr',[_c('td',[_vm._v("Vitesse Initiale")]),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.initRateBT1),callback:function ($$v) {_vm.$set(_vm.inputs, "initRateBT1", $$v)},expression:"inputs.initRateBT1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.initRateBT2),callback:function ($$v) {_vm.$set(_vm.inputs, "initRateBT2", $$v)},expression:"inputs.initRateBT2"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('v-select',{attrs:{"items":_vm.unitOptions,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.unitInitRateB),callback:function ($$v) {_vm.$set(_vm.inputs, "unitInitRateB", $$v)},expression:"inputs.unitInitRateB"}})],1)]),_c('tr',[_c('td',[_vm._v("1/T")]),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.inverseTempET1),callback:function ($$v) {_vm.$set(_vm.inputs, "inverseTempET1", $$v)},expression:"inputs.inverseTempET1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.inverseTempET2),callback:function ($$v) {_vm.$set(_vm.inputs, "inverseTempET2", $$v)},expression:"inputs.inverseTempET2"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('v-select',{attrs:{"items":_vm.unitOptions2,"outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item}})]}}]),model:{value:(_vm.inputs.unitInverseTempE),callback:function ($$v) {_vm.$set(_vm.inputs, "unitInverseTempE", $$v)},expression:"inputs.unitInverseTempE"}})],1)]),_c('tr',[_c('td',[_vm._v("ln(Vitesse Initiale)")]),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.lnInitRateET1),callback:function ($$v) {_vm.$set(_vm.inputs, "lnInitRateET1", $$v)},expression:"inputs.lnInitRateET1"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0 centered-input"},[_c('calculation-input',{staticClass:"centered-input",attrs:{"disabled":!_vm.allowEditing},model:{value:(_vm.inputs.lnInitRateET2),callback:function ($$v) {_vm.$set(_vm.inputs, "lnInitRateET2", $$v)},expression:"inputs.lnInitRateET2"}})],1),_c('td',{staticClass:"py-2 px-2 mx-0",staticStyle:{"text-align":"center"}},[_c('stemble-latex',{attrs:{"content":"$\\text{N/A}$"}})],1)])])])],1),_c('p',{staticClass:"mb-3"},[_vm._v(" Basé sur les données recueillies ici, quelle est l'énergie d'activation pour la décomposition du peroxyde d'hydrogène lorsqu'il est catalysé par la catalase ? ")]),_c('calculation-input',{staticClass:"mb-5",attrs:{"prepend-text":"$\\text{E}_\\text{a}:$","append-text":"$\\text{kJ/mol}$","disabled":!_vm.allowEditing},model:{value:(_vm.inputs.activationEnergyE),callback:function ($$v) {_vm.$set(_vm.inputs, "activationEnergyE", $$v)},expression:"inputs.activationEnergyE"}}),_c('p',{staticClass:"mb-2 mt-2"},[_vm._v(" Dans l'espace prévu ci-dessous, veuillez montrer votre travail pour le calcul de l'énergie d'activation que vous avez déclaré ci-dessus. ")]),_c('p',{staticClass:"mb-n2"},[_c('s-textarea',{attrs:{"outlined":""},model:{value:(_vm.inputs.eaCalcWork),callback:function ($$v) {_vm.$set(_vm.inputs, "eaCalcWork", $$v)},expression:"inputs.eaCalcWork"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }